export default {
  en: {
    title: 'Admin area',
    password: 'Password',
    login: 'Login',
  },
  fr: {
    title: 'Espace admin',
    password: 'Mot de passe',
    login: 'Connexion',
  },
};
