import __projectConfig from './project.config.js';

import __i18n from '~/components/i18n.js';

// @ts-ignore
export default defineI18nConfig(() => ({
  legacy: false,
  locale: __projectConfig.locale.default,
  messages: {
    ...__i18n,
  },
}));
