import __login from './login/login.i18n.js';
import __admin from './admin/admin.i18n.js';
import __search from './search/search.i18n.js';

import { __flatten } from '@lotsof/sugar/object';

export default {
  fr: {
    ...__flatten({
      login: __login.fr,
      admin: __admin.fr,
      search: __search.fr,
    }),
  },
  en: {
    ...__flatten({
      login: __login.en,
      admin: __admin.en,
      search: __search.en,
    }),
  },
};
